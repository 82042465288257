import axios from 'axios';
import { get } from "lodash";
import { getCookie, deleteCookie } from "../helpers/storage";

var api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});
api.interceptors.request.use((config) => {
    let conf = {
        ...config,
        headers: {
            ...config.headers,
            'Authorization': 'Bearer ' + (getCookie() || false)
        }
    }
    return conf;
})

api.interceptors.response.use(response => {
    let data = get(response, "data");
    return {
        ...response,
        data
    }
}, (error) => {
    const { status } = get(error, "response", {});
    if (status === 401) {
        deleteCookie();
        window.location.href = `${process.env.REACT_APP_URL_PREFIX}/`;
    }
    return Promise.reject(error)
})

export default api;